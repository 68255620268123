<template>
	<div class="customize-page">
		<CustomizeVisualizer
			v-for="form in customized"
			:key="form.id + form.command + form.type"
			:overrideForm="form"
		/>
		<div v-if="!customized.length">None to show!</div>
		<div class="page-navigator">
			<v-btn :disabled="page == 1" @click="prevPage">Prev</v-btn>
			<span class="mx-3">{{ page }}</span>
			<v-btn @click="nextPage">next</v-btn>
		</div>
	</div>
</template>

<script>
import CustomizeVisualizer from '@/views/customize/components/CustomizeVisualizer';

export default {
	name: 'AdminCustomizedPage',
	components: { CustomizeVisualizer },
	data: () => ({
		customized: [],
		page: 1,
		command: null,
	}),
	watch: {
		$route() {
			this.init();
		},
	},
	methods: {
		async nextPage() {
			this.page++;
			this.customized = await this.$store.dispatch('getCustomizedCommands', {
				page: this.page,
				command: this.command,
			});
		},
		async prevPage() {
			this.page--;
			this.customized = await this.$store.dispatch('getCustomizedCommands', {
				page: this.page,
				command: this.command,
			});
		},
		async init() {
			this.page = 1;
			this.command = this.$route.params.command;
			this.customized = await this.$store.dispatch('getCustomizedCommands', {
				page: this.page,
				command: this.command,
			});
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.page-navigator {
	width: 100%;
	display: flex;
	height: 100px;
	justify-content: center;
	align-items: center;
}
</style>
